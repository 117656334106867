<template>
  <section>
    <b-row
      ><b-col md="12">
        <!-- <b-card> -->
        <b-tabs v-model="currentTab">
          <b-tab>
            <!-- title -->
            <template #title>
              <feather-icon icon="ArchiveIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Detail</span>
            </template>
            <b-modal
              v-model="Modal"
              id="modal-tambah"
              centered
              size="xl"
              :title="titleModal"
            >
              <b-card-text>
                <b-form>
                  <b-row>
                    <b-col sm="4">
                      <b-form-group label="Kategori Paket" label-for="kategori">
                        <b-form-select
                          v-model="ujianDataId.category_paket_id"
                          :options="optPaket"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="8">
                      <b-form-group label="Nama Ujian" label-for="nama-paket">
                        <b-form-input
                          v-model="ujianDataId.name"
                          placeholder="Nama Ujian"
                          name="nama-paket"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        label="Nama Ujian (singkat)"
                        label-for="harga"
                      >
                        <b-form-input
                          v-model="ujianDataId.shortname"
                          name="shortname"
                          placeholder="shortname"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group label="Acak Soal" label-for="acak">
                        <b-form-select
                          v-model="ujianDataId.shuffle_question"
                          :options="optquestion"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="4">
                      <b-form-group
                        label="Tampilkan Hasil"
                        label-for="show_result"
                      >
                        <b-form-select
                          v-model="ujianDataId.show_result"
                          :options="optresult"
                        />
                      </b-form-group>
                    </b-col>
                    <b-col sm="12">
                      <b-form-group label="Instruksi" label-for="instruksi">
                        <quill-editor
                          id="Konten"
                          v-model="ujianDataId.instruction"
                          :options="editor_options"
                        >
                        </quill-editor>
                      </b-form-group>
                    </b-col>
                  </b-row>
                </b-form>
              </b-card-text>

              <template #modal-footer>
                <div class="w-100">
                  <p class="float-left mb-0"></p>

                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    type="submit"
                    variant="outline-success"
                    class="mr-1 btn-icon"
                    @click.prevent="Tambah"
                  >
                    <feather-icon icon="SaveIcon" class="mr-25" />{{
                      label || "Tambah"
                    }}
                  </b-button>
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="outline-primary"
                    class="float-right btn-icon"
                    @click.prevent="tutupModal"
                  >
                    <feather-icon icon="LogOutIcon" class="mr-25" />Tutup
                  </b-button>
                </div>
              </template>
            </b-modal>
            <b-card>
              <!-- form -->
              <b-row>
                <b-col cols="9" xl="9">
                  <table class="mt-xl-0 w-100">
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="UserIcon" class="mr-75" />
                        <span class="font-weight-bold">Nama Ujian</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{
                          ujianDataId.name +
                          " - (" +
                          ujianDataId.shortname +
                          ")"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Kategori Paket</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        {{
                          ujianDataId.category_paket != null
                            ? ujianDataId.category_paket.name
                            : "-"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="UserIcon" class="mr-75" />
                        <span class="font-weight-bold">Acak Soal</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{ ujianDataId.shuffle_question == 1 ? "Ya" : "Tidak" }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="UserIcon" class="mr-75" />
                        <span class="font-weight-bold">Tampilkan Hasil</span>
                      </th>
                      <td class="pb-50">
                        :
                        {{ ujianDataId.show_result == 1 ? "Ya" : "Tidak" }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold"
                          >Jenis Kalkulasi Skor Ujian</span
                        >
                      </th>
                      <td class="pb-50">
                        :
                        {{
                          ujianDataId.type_score_calculation ==
                          "question_weight_based"
                            ? "Berdasarkan Bobot Soal"
                            : "Berdasarkan Poin Opsi Soal"
                        }}
                      </td>
                    </tr>
                    <tr>
                      <th class="pb-50">
                        <feather-icon icon="CheckIcon" class="mr-75" />
                        <span class="font-weight-bold">Instruksi Ujian</span>
                      </th>
                      <td class="pb-50 text-capitalize">
                        :
                        <div v-html="ujianDataId.instruction"></div>
                      </td>
                    </tr>
                  </table>
                </b-col>
                <b-col cols="3" xl="3">
                  <b-button
                    variant="primary"
                    @click="ModalUbah(ujianDataId)"
                    block
                    >Edit</b-button
                  >
                  <!-- <b-button variant="primary" block>Hapus</b-button> -->
                </b-col>
                <hr />
              </b-row>
            </b-card>
          </b-tab>
          <b-tab>
            <template #title>
              <feather-icon icon="CheckCircleIcon" size="18" class="mr-50" />
              <span class="font-weight-bold">Soal Ujian</span>
            </template>
            <soal-ujian2 :ujianDataId="ujianDataId" />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
import { quillEditor } from "vue-quill-editor";
import {
  BMedia,
  BMediaAside,
  BMediaBody,
  BImg,
  BAlert,
  BLink,
  BTabs,
  BTab,
  BRow,
  BCol,
  BTable,
  BCard,
  BCardText,
  BCardTitle,
  BCardBody,
  BPagination,
  BProgress,
  BBadge,
  BButton,
  BModal,
  VBModal,
  BForm,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BFormFile,
  BInputGroup,
  BInputGroupAppend,
  BFormRadioGroup,
  BFormTextarea,
  VBTooltip,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import SoalUjian from "./soal/Index.vue";
import SoalUjian2 from "./soal/Index2.vue";
import SoalUjian3 from "./soal/IndexTambah.vue";
import flatPickr from "vue-flatpickr-component";

export default {
  components: {
    quillEditor,
    SoalUjian,
    SoalUjian3,
    SoalUjian2,
    BMedia,
    BMediaAside,
    BMediaBody,
    BImg,
    BAlert,
    BLink,
    BTabs,
    BTab,
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardTitle,
    BCardBody,
    BTable,
    BPagination,
    BProgress,
    BBadge,
    BButton,
    BModal,
    VBModal,
    BForm,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormFile,
    BInputGroup,
    BInputGroupAppend,
    BFormRadioGroup,
    BFormTextarea,
    VBTooltip,
    ToastificationContent,
    flatPickr,
  },
  directives: {
    "b-modal": VBModal,
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      currentTab: 0,
      editor_options: {
        modules: {
          toolbar: {
            container: [
              ["bold", "italic", "underline", "strike"],
              ["blockquote", "code-block"],
              [{ header: 1 }, { header: 2 }],
              [{ list: "ordered" }, { list: "bullet" }],
              [{ script: "sub" }, { script: "super" }],
              [{ indent: "-1" }, { indent: "+1" }],
              [{ direction: "rtl" }],
              [{ size: ["small", false, "large", "huge"] }],
              [{ header: [1, 2, 3, 4, 5, 6, false] }],
              [{ color: [] }, { background: [] }],
              [{ font: [] }],
              [{ align: [] }],
              ["clean"],
            ],
          },
        },
      },
      optquestion: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      optresult: [
        { value: "1", text: "Ya" },
        { value: "0", text: "Tidak" },
      ],
      filterPaket: null,
      optPaket: [],
      Modal: false,
      id: null,
      activeAction: null,
      label: "Simpan",
      //Data
      paketFAQData: [],
      ujianDataId: {},
      optkatujian: [],
      optmapel: [],
      // form: {
      //     id: null,
      //     name: null,
      //     price: null,
      //     paket_id: null,
      // },
      //Modal
      titleModal: null,
      Modal: false,
      ModalUploadThumbnail: false,
      //Table
      fields: [
        { key: "index", label: "No" },
        { key: "name", label: "Pertanyaan", sortable: true },
        { key: "price", label: "Jawaban", sortable: true },
        { key: "action", label: "#" },
      ],
      selected: [],
      total: [],
      record: [],
      optFilter: [
        { id: "name", value: "Pertanyaan" },
        { id: "price", value: "Jawaban" },
        // { id: "nip", value: "NIP" },
      ],
      perPage: 10,
      pageOptions: [10, 30, 50],
      totalRows: 1,
      currentPage: 1,
      sortBy: "",
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      filterOn: [],
      // filter: "Paket",
      search: null,
      idd: null,
    };
  },
  watch: {},
  computed: {
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => ({ text: f.label, value: f.key }));
    },
  },
  methods: {
    ModalUbah(item) {
      this.ujianDataId = item;
      // this.ujianDataId.type_ujian = item.category.id;
      // this.ujianDataId.mapel_id = item.mapel.id;
      this.ujianDataId.category_paket_id = item.category_paket.id;
      this.activeAction = "ubah";
      this.titleModal = "Ubah Ujian";
      this.getDataKategoriUjian();
      this.getDataMapel();
      this.getDataKategoriPaket();
      this.Modal = true;
      // this.ModalEdit = true;
    },
    doFormatRupiah() {
      this.ujianDataId.price = this.formatRupiah(this.ujianDataId.price);
      this.ujianDataId.max_quota = this.formatRupiah(
        this.ujianDataId.max_quota
      );
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    ModalUpload() {
      this.id = this.$route.params.id;
      this.activeAction = "upload";
      this.doFormatRupiah();
      this.titleModal = "Upload Thumbnail";
      this.ModalUploadThumbnail = true;
    },
    ModalShow() {
      this.id = null;
      this.activeAction = "tambah";
      this.doFormatRupiah();
      this.titleModal = "Tambah Paket";
      this.Modal = true;
      // this.ModalEdit = false;
    },
    ModalHapus(data) {
      this.$swal({
        title: "Anda Yakin?",
        text: "Data tidak bisa dikembalikan!",
        icon: "warning",
        showCancelButton: true,
        cancelButtonText: "Batal",
        confirmButtonText: "Ya, Hapus!",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          data.fungsi = 1; //soft delete
          this.$store
            .dispatch("adminujian/createUpdateDelete", [data])
            .then(() => {
              this.getDataById();
              this.displaySuccess({
                text: "Data berhasil dihapus",
              });
            })
            .catch((e) => {
              this.displayError(e);
              return false;
            });
          this.$swal({
            icon: "success",
            title: "Terhapus!",
            text: "Data berhasil dihapus.",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        } else if (result.dismiss === "cancel") {
          this.$swal({
            title: "Batal",
            text: "Data aman, kamu batal menghapus data :)",
            icon: "error",
            // timer: 1000,
            allowOutsideClick: true,
            customClass: {
              confirmButton: "btn btn-success",
            },
            buttonsStyling: false,
          });
        }
      });
    },
    pesanGagal() {
      this.$toast(
        {
          component: ToastificationContent,
          props: {
            title: "Notifikasi",
            icon: "CheckIcon",
            text: "Gagal menyimpan data, harap cek kembali data yang diisi",
            variant: "warning",
          },
        },
        {
          position: "bottom-right",
        }
      );
    },
    Tambah() {
      if (this.ujianDataId.name == null || this.ujianDataId.name == "") {
        this.pesanGagal();
        return false;
      }
      let payload = {
        id: this.ujianDataId.id,
        // type_ujian: this.ujianDataId.type_ujian,
        category_paket_id: this.ujianDataId.category_paket_id,
        user_id: this.user.id,
        // mapel_id: this.ujianDataId.mapel_id,
        name: this.ujianDataId.name,
        shortname: this.ujianDataId.shortname,
        show_result: this.ujianDataId.show_result,
        shuffle_question: this.ujianDataId.shuffle_question,
        instruction: this.ujianDataId.instruction,
        // soal_ids: this.ujianDataId.soal_ids,
      };

      // if (this.id) {
      //   payload.id = this.id;
      // }

      this.$store
        .dispatch("adminujian/save", [payload])
        .then(() => {
          if (this.activeAction == "tambah") {
            this.id = null;
          }
          this.Modal = false;
          // this.ModalEdit = false;
          this.getDataById();
          this.displaySuccess({
            text: "Ujian berhasil di" + this.activeAction,
          });
          // location.reload();
        })
        .catch((e) => {
          this.displayError(e);
          return false;
        });
    },
    async getDataById() {
      this.$store
        .dispatch("adminujian/indexId", this.$route.query.ujian_id)
        .then((response) => {
          let ujianDataId = response.data;
          this.ujianDataId = ujianDataId;
          this.ujianDataId.type_ujian = ujianDataId.category
            ? ujianDataId.category.id
            : null;
          this.ujianDataId.mapel_id = ujianDataId.mapel
            ? ujianDataId.mapel.id
            : null;
          this.doFormatRupiah();
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriUjian() {
      // this.loading = true;
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
        user_id: this.user.id,
      };
      this.$store
        .dispatch("adminujian/indexKategoriUjian", payload)
        .then((response) => {
          // this.loading = false;
          let optkatujian = response.data.data;
          optkatujian.map((item) => {
            item.value = item.id;
            item.text = item.nama_ujian;
          });
          this.optkatujian = optkatujian;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },

    async getDataMapel() {
      // this.loading = true;
      const payload = {};
      this.$store
        .dispatch("mapel/index", payload)
        .then((response) => {
          // this.loading = false;
          let optmapel = response.data.data;
          optmapel.map((item) => {
            item.value = item.id;
            item.text = item.nama_mapel;
          });
          this.optmapel = optmapel;
        })
        .catch((error) => {
          // this.loading = false;
          this.$root.$emit("errorData", error);
        });
    },
    async getDataKategoriPaket() {
      const payload = {
        // sekolah_id: localStorage.getItem("sekolah"),
        // filter: this.filter !== null ? this.filter.id : "name",
        // search: this.search,
        // start: (this.currentPage - 1) * this.itemsPerPage,
        // length: this.itemsPerPage,
      };
      this.$store
        .dispatch("paketTryout/indexKategoriPaket", payload)
        .then((response) => {
          let optPaket = response.data.data;
          optPaket.map((item) => {
            item.value = item.id;
            item.text = item.name;
          });
          this.optPaket = optPaket;
          // this.totalRows = this.katsoalData.length;
        })
        .catch((error) => {
          this.$root.$emit("errorData", error);
        });
    },
  },
  created() {
    if (this.$route.query.active_tab == "soal") {
      this.currentTab = 1;
    }
  },
  mounted() {
    this.getDataById();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
